import styled from '@emotion/styled';

export const Wrapper = styled.div<{ type?: string }>(({ theme, type }) => ({
  margin: 0,
  position: 'relative',

  ...(type !== 'fixed' && {
    margin: `-40px -${theme.spacings.l}px ${theme.spacings.l}px`,

    [theme.mediaQueries.large]: {
      margin: 0,
    },
  }),
}));

export const Picture = styled.picture(({ theme }) => ({
  marginBottom: theme.spacings.xxl,
  width: `calc(100vw + ${theme.spacings.xl}px)`,
  marginTop: 116,

  img: {
    width: '100%',
  },

  [theme.mediaQueries.medium]: {
    margin: 0,
  },
}));

export const Copy = styled.div(({ theme }) => ({
  display: 'flex',
  textAlign: 'right',
  margin: theme.spacings.s,
  marginRight: theme.spacings.l,
  justifyContent: 'end',
}));

export const HeroTitle = styled.h1(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  bottom: theme.spacings.l,
  left: theme.spacings.l,
  right: theme.spacings.l,
  color: theme.colors.white,
  //fontSize: "36px",
  margin: 0,
  fontSize: '40px',

  p: {
    margin: 0,
  },
}));
